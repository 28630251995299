<template>
  <div>
    <slot name="button" :modal-id="modalId"></slot>
    <div
      class="modal fade"
      :id="modalId"
      tabindex="-1"
      role="dialog"
      ref="modal"
      aria-labelledby="show-item-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="show-item-modal-label">{{title}}</h5>
            <button type="button" class="close" aria-label="Close" @click="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="modal-item"></slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Show Item title here',
    },
  },

  methods: {
    closeModal() {
      jQuery(this.$refs["modal"]).modal('hide');
      this.$emit('closed')
    },
  },

  computed: {
    modalId() {
      return _.uniqueId('show-item-')
    },
  },
}
</script>