<script>
import { Line } from 'vue-chartjs'

export default {
    extends: Line,

    data() {
        return {
            traceDates: [],
            dailyGraphCounts: [],
        };
    },

    mounted() {
        axios.get(
            route('admin-user-profiles-summary-daily')
        ).then(response => {
            this.traceDates = response.data.tracedates;
            this.dailyGraphCounts = response.data.dailygraphcounts;
            this.makeChart();
        }).catch(error => {
            $.growl.error({
                message: error.response.data.message
                    || 'There was an error processing your request' 
            })
        });
    },

    methods: {
        makeChart() {
            var datasets = [{
                    data: this.dailyGraphCounts,
                    label: "New Confirmed Members",
                    borderColor: '#B30000',
                    backgroundColor: '#B30000',
                    fill: false
            }];

            this.renderChart({
                labels: this.traceDates,
                datasets: datasets,        
            },
            {
                elements: {
                    point: { radius: 0 },
                    line: { tension: 0, borderWidth: 1.5 },
                },
                scales: {
                    xAxes: [{ scaleLabel: { display: true, labelString: 'Past 60 Days' } }],
                    yAxes: [{ scaleLabel: { display: true, labelString: 'Number of Memberships' } }],
                }
            })
        }
    },
}
</script>
