<template>
    <div class="container-fluid px-0">
        <form @submit.prevent="submit">
            <div class="form-row">
                <div class="form-group col-12">
                    <label for="survey" class="mb-2 pl-2 small">Select registration-survey for 
                        <strong v-text="eventName"></strong>
                    </label>
                    <select 
                            v-model="survey" 
                            class="browser-default custom-select" 
                            id="surveylist" 
                            name="surveylist" 
                            required>
                        <option disabled selected value="">-Survey options-</option>
                        <option v-for="survey in surveys" 
                                :key="survey.id"
                                :value="survey">
                            {{ survey.title }} (ID: {{ survey.id }})
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        surveys: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            survey: '',
            event: null
        }
    },
    
    computed: {
        eventName: function() {
            if (this.event !== null) 
                return this.event.name
            else
                return ""
        },
    },

    methods: {
        submit() {
            axios.post(route('admin-event-survey-configure', {
                event: this.event.id,
                survey: this.survey.id,
            })).then(response => {
                $.growl.notice({ message: `Survey (${this.survey.title}) successfully attached.` })
                window.location.reload(true);
            }).catch(error => {
                console.log(error)
                $.growl.error({
                    message: error.response.data.message
                        || 'There was an error processing your request' })
            });
        },
    },
    
    mounted() {
        let _this = this;

        // Listen to event trigger called "configureSurvey"
        $(document).on("configureSurvey", function(event, eventObj) {
            _this.event = eventObj;
            if (eventObj !== null && eventObj.options !== null && ("registration_survey_id" in eventObj.options)) {
                var surveyId = eventObj.options["registration_survey_id"];
                if (surveyId !== null) {
                    _this.survey = _this.surveys.find(survey => survey.id == surveyId);
                }
                else {
                    _this.survey = null;
                }
            }
            else {
                _this.survey = null;
            }
        })

        $(document).on("saveSurveyConfiguration", function(event) {
            _this.submit();
        })

        $(document).on("removeSurvey", function(event) {
            axios.delete(route('admin-event-survey-remove', {
                    event: _this.event.id,
                })).then(response => {
                    $.growl.notice({ message: `Survey removed successfully.` })
                    window.location.reload(true);
                }).catch(error => {
                    $.growl.error({
                        message: error.response.data.message
                            || 'There was an error processing your request' })
                })

        })
    },

}
</script>