<template>
    <div class="form-row">
        <div class="col-12">
            <span v-for="(abstractSession, index) in abstractSessions"
                    class="badge orange mr-1">
                {{ abstractSession.title }}
                <a @click.prevent="removeSession(index)">
                    <i class="fas fa-times"></i>
                </a>
            </span>
        </div>
        <div class="col-9">
            <select class="custom-select mt-1" v-model="selectedSession">
                <option :value="null">- none -</option>
                <template v-for="(session, index) in eventSessions">
                    <option v-if="!isAbstractSession(session)" :value="session">
                        {{ session.title }}
                    </option>
                </template>
            </select>
        </div>
        <div class="col-3">
            <button class="btn btn-primary btn-sm"
                    @click.prevent="addSession">Add</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        abstract: Object,
        eventSessions: Array,
    },
    data() {
        return {
            abstractSessions: this.abstract.event_sessions,
            selectedSession: null,
        }
    },
    methods: {
        isAbstractSession(session) {
            for(let e in this.abstractSessions) {
                if (this.abstractSessions[e].id === session.id) {
                    return true;
                }
            }

            return false;
        },
        removeSession(index) {
            axios.get(route('admin-remove-abstract-session', {
                sessionId: this.abstractSessions[index].id,
                abstractId: this.abstract.id,
            })).then(response => {
                if(response.data && response.data.success) {
                    $.growl.notice({ message: "Successfully removed "+this.abstractSessions[index].title+"!" });
                    this.abstractSessions.splice(index,1);
                }
            }).catch(error => {
                $.growl.error({ message: "Could not remove "+this.abstractSessions[index].title+"!" });
            });
        },
        addSession() {
            if (this.selectedSession == null) {
                return;
            }
            axios.get(route('admin-add-abstract-session', {
                sessionId: this.selectedSession.id,
                abstractId: this.abstract.id,
            })).then(response => {
                if(response.data) {
                    if(response.data.success) {
                        $.growl.notice({ message: "Successfully added "+this.selectedSession.title+"!" });
                        this.abstractSessions.push(this.selectedSession);
                        this.selectedSession = null;
                    }
                    else {
                        $.growl.notice({ message: response.data.message });
                    }
                }
            }).catch(error => {
                $.growl.error({ message: "Could not add "+this.selectedSession.title+"!" });
            });
        }
    }
}
</script>
