// noinspection JSUnresolvedFunction
// Suppress warnings from IDE not recognizing the 'component', etc. functions.

/* Bootstrap contains the CSRF protection. */
/* FontAwesome */
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {dom} from "@fortawesome/fontawesome-svg-core";
/* Playr */
import VuePlyr from "vue-plyr";
/* Ziggy */
import {ZiggyVue} from "ziggy";
import {Ziggy} from "./ziggy";
/* Notifications (Toasts) */
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
/* Loading UI Blocker */
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
/* Global mixins */
import InteractsWithApi from "./mixins/InteractsWithApi";
/* Promisifying Dialogs */
import * as ModalDialogs from "vue-modal-dialogs";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';


/**
 * Globally registering base functions.
 */
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import Vue from 'vue';
import {Helpers} from "./common/functions";

require("./bootstrap");

const csrf_token = document.head.querySelector('meta[name="csrf-token"]').content;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;


/* Imports, Requirements, and their configs. -------------------------------- */
try {
  /* Remember to restart npm watch/dev if you change the .env value. */
  Bugsnag.start({
    apiKey: process.env.MIX_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
} catch (ex) {
  console.log('Unable to log errors to Bugsnag:');
  console.log(ex);
}

Vue.component("font-awesome-icon", FontAwesomeIcon);
dom.watch();

Vue.use(VuePlyr, {
  plyr: {
    controls: [
      "play-large",
      "restart",
      "rewind",
      "play",
      "fast-forward",
      "progress",
      "current-time",
      "duration",
      "mute",
      "volume",
      "fullscreen",
      "settings"
    ],
    quality: {default: 360},
    fullscreen: {enabled: true}
  },
  emit: ["ended"]
});

Vue.use(ZiggyVue, Ziggy)

Vue.use(Toast, {});

Vue.component("loading", Loading);
Vue.use(Loading, {
  canCancel: false,
  color: "#000000",
  loader: "bars",
  backgroundColor: "#ffffff",
  opacity: 0.5
});


/* Moment (to be removed) */
const moment = require('moment-timezone');
Vue.use(require("vue-moment"), {moment});

Vue.mixin(InteractsWithApi);

Vue.use(ModalDialogs);

/* Laravel Vue Pagination */
Vue.component("pagination", require("laravel-vue-pagination"));


/*
 * TODO: Determine whether the following definition of window constants is
 * required.
 *
 * Some components appear to work fine using just the global constants. Others,
 * particularly those using mixins, appear to need it defined as part of the
 * window.
 */
window.Vue = Vue;
window.moment = moment;

// Register Base Components. ---------------------------------------------------
const baseComponents = require.context(
  "./components/ui",
  true,
  /Base[A-Z]\w+\.(vue|js)$/
);
baseComponents.keys().forEach(filename => {
  const componentConfig = baseComponents(filename);
  const componentName = upperFirst(
    camelCase(filename.split("/").pop().replace(/\.\w+$/, ""))
  );
  /*
   * Look for the component options on `.default`, which will
   * exist if the component was exported with `export default`,
   * otherwise fall back to module's root.
   */
  Vue.component(componentName, componentConfig.default || componentConfig);
});


// Register Custom Components --------------------------------------------------

// Auth
Vue.component("auth-login-form", () =>
  import("./components/auth/LoginForm.vue"));
Vue.component("auth-password-reset-link-request-form", () =>
  import("./components/auth/PasswordResetLinkRequestForm.vue"));
Vue.component('auth-password-reset-form', () =>
  import("./components/auth/PasswordResetForm.vue"));
Vue.component('auth-resend-confirmation', () =>
  import("./components/auth/ResendConfirmation.vue"));

Vue.component('agreements-sign', () =>
  import("./components/agreements/Sign.vue"));

// Financial & Payment
Vue.component("payment-purchase-order-review", () =>
  import("./components/payment/PurchaseOrderReview.vue"))
Vue.component("payment-stripe-payment-form", () =>
  import("./components/payment/StripePaymentForm.vue"))
Vue.component("payments-index", () =>
  import("./components/payments/Index.vue"))

// Society
Vue.component("society-donate-form", () =>
  import("./components/society/DonateForm.vue"));
Vue.component("society-contact-form", () =>
  import("./components/society/ContactForm.vue"));
Vue.component("society-membership-payment-form", () =>
  import("./components/society/membership/PaymentForm.vue"));

Vue.component("society-events-user-dashboards", () =>
  import("./components/society/events/UserDashboards"));
Vue.component("society-events-abstracts-index", () =>
  import("./components/society/events/abstracts/Index"));
Vue.component("society-events-abstracts-editor", () =>
  import("./components/society/events/abstracts/editor/Editor"));

Vue.component("society-events-presentation-presenter-media", () =>
  import("./components/society/events/presentation/presenter-media/Index.vue"));

Vue.component("society-events-registration-agreements", () =>
  import("./components/society/events/registration/Agreements.vue"));
Vue.component("society-events-registration-option-selector", () =>
  import("./components/society/events/registration/OptionSelector"));
Vue.component("society-events-registration-presentation-agreements", () =>
  import("./components/society/events/registration/PresentationAgreements.vue"));
Vue.component("society-events-checkins-index", () =>
  import("./components/society/events/check-ins/Index.vue"));

Vue.component("society-job-listings-index", () =>
  import("./components/society/job-listings/Index.vue"));
Vue.component("society-job-listings-update-controls", () =>
  import("./components/society/job-listings/UpdateControls.vue"));
Vue.component("society-job-listings-editor", () =>
  import("./components/society/job-listings/Editor.vue"));
Vue.component("society-job-listings-current-user-index", () =>
  import("./components/society/job-listings/current-user/Index.vue"));

Vue.component("society-news-articles-index", () =>
  import("./components/society/news-articles/Index"));

// Survey
Vue.component("survey-show", () =>
  import("./components/surveys/Show.vue"));

// User
Vue.component("user-mailing-preferences", () =>
  import("./components/user/mailing/Preferences.vue"));
Vue.component("user-profile-account-details", () =>
  import("./components/user/profile/AccountDetails.vue"));
Vue.component("user-profile-details", () =>
  import("./components/user/profile/Details.vue"));
Vue.component("user-activity-profile-summary", () =>
  import("./components/user/profile/activity/ProfileSummary.vue"));
Vue.component("user-activity-payments-index", () =>
  import("./components/user/profile/activity/payments/Index.vue"));
Vue.component("user-activity-abstract-submissions", () =>
  import("./components/user/profile/activity/AbstractSubmissions.vue")
);

// Video
Vue.component("video-playlist-view", () =>
  import("./components/video/playlist/View.vue"))
Vue.component("video-playlist-show", () =>
  import("./components/video/playlist/Show.vue"))
Vue.component("admin-video-playlist-manage", () =>
  import("./components/admin/video/playlist/Manage.vue"))
Vue.component("admin-video-playlist-show", () =>
  import("./components/admin/video/playlist/Show.vue"))
Vue.component("admin-video-manage", () =>
  import("./components/admin/video/Manage.vue"))

// Mailing List ----------------------------------------------------------------
Vue.component("mailing-lists-index", () =>
  import("./components/mailing/lists/Index.vue"));
Vue.component("mailing-list-messages-index", () =>
  import("./components/mailing/messages/Index.vue"))
Vue.component("mailing-list-messages-editor", () =>
  import("./components/mailing/messages/Editor.vue"))
Vue.component("mailing-list-email-manager", () =>
  import("./components/mailing/lists/email/Manager.vue"))
Vue.component("mailing-list-email-providers-index", () =>
  import("./components/mailing/email-providers/Index.vue"))
Vue.component("mailing-list-message-views-index", () =>
  import("./components/mailing/message-views/Index.vue"))

// General Purpose Components
Vue.component("home-dashboard", () =>
  import("./components/dashboard/HomeDashboard.vue"));
Vue.component("nav-dashboard", () =>
  import("./components/ui/dashboard/NavDashboard"));
/* MDBootstrap Components. */
Vue.component("pre-loader-spinner", () =>
  import("./components/mdb/PreLoaderSpinner.vue"));

// Admin
Vue.component("admin-agreements-editor",
  () => import("./components/admin/agreements/Editor.vue"));
Vue.component("admin-agreements-index",
  () => import("./components/admin/agreements/Index.vue"));
Vue.component("admin-agreements-signatures-index",
  () => import("./components/admin/agreements/signatures/Index.vue"));
Vue.component("admin-users-index", () =>
  import("./components/admin/users/Index.vue"));
Vue.component("admin-dashboard-manager", () =>
  import("./components/admin/dashboard/Manager.vue"));
Vue.component("admin-society-job-listings-index", () =>
  import("./components/admin/society/job-listings/Index.vue"));
Vue.component("admin-society-news-articles-index", () =>
  import("./components/admin/society/news-articles/Index.vue"));
Vue.component("admin-society-news-articles-editor", () =>
  import("./components/admin/society/news-articles/Editor.vue"));
Vue.component("admin-surveys-index", () =>
  import("./components/admin/surveys/Index.vue"));
Vue.component("admin-survey-items-index", () =>
  import("./components/admin/surveys/survey-items/Index.vue"));
Vue.component("admin-survey-results-index", () =>
  import("./components/admin/surveys/survey-results/Index.vue"));
Vue.component("admin-survey-item-results-index", () =>
  import("./components/admin/surveys/survey-item-results/Index.vue"));

// Admin Event Management
Vue.component("admin-society-events-index",
  () => import("./components/admin/society/events/Index"));
Vue.component("admin-society-events-show",
  () => import("./components/admin/society/events/Show"));
Vue.component("admin-society-events-abstracts-index",
  () => import("./components/admin/society/events/abstracts/Index"));
Vue.component("admin-society-events-abstracts-review",
  () => import("./components/admin/society/events/abstracts/review/Index"));
Vue.component("admin-society-events-abstract-agreements",
  () => import("./components/admin/society/events/abstract-agreements/Index"));

Vue.component("admin-society-events-abstract-review-configure",
  () => import("./components/admin/society/events/abstract-review/Configure"));
Vue.component("admin-society-events-abstract-submission-configure",
  () => import("./components/admin/society/events/abstract-submission/Configure"));
Vue.component("admin-society-events-dashboard-links-configure",
  () => import("./components/admin/society/events/dashboard-links/Index"));
Vue.component("admin-society-events-keywords-configure",
  () => import("./components/admin/society/events/keywords/Index"));
Vue.component("admin-society-events-presentation-options-configure",
  () => import("./components/admin/society/events/presentation-options/Configure"));
Vue.component("admin-society-events-presentation-presenter-media-index",
  () => import("./components/admin/society/events/presentation/presenter-media/Index.vue"));
Vue.component("admin-society-events-program-poster-boards-index",
  () => import("./components/admin/society/events/program/poster-boards/Index.vue"));
Vue.component("admin-society-events-program-presentation-decisions-index",
  () => import("./components/admin/society/events/program/presentation-decisions/Index.vue"));
Vue.component("admin-society-events-program-sessions-configure",
  () => import("./components/admin/society/events/program/SessionsConfigure.vue"));
Vue.component("admin-society-events-program-session-abstracts-index",
  () => import("./components/admin/society/events/program/session-abstracts/Index.vue"));


Vue.component("admin-society-events-presentation-agreements",
  () => import("./components/admin/society/events/presentation/agreements/Index.vue"));

Vue.component("admin-society-events-registration-agreements",
  () => import("./components/admin/society/events/registration/agreements/Index.vue"));
Vue.component("admin-society-events-registration-configure",
  () => import("./components/admin/society/events/registration/Configure"));
Vue.component("admin-society-events-registration-types-configure",
  () => import("./components/admin/society/events/registration/types/Configure"));
Vue.component("admin-society-events-registration-payables-configure",
  () => import("./components/admin/society/events/registration/payables/Configure"));
Vue.component("admin-society-events-registration-types",
  () => import("./components/admin/society/events/registration-types/Index"));

Vue.component("admin-society-events-registrations-index",
  () => import("./components/admin/society/events/registrations/Index.vue"));
Vue.component("admin-society-events-registrations-summary",
  () => import("./components/admin/society/events/registrations/summary/Summary.vue"));


// Admin Financial
Vue.component("admin-financial-payables-index", () =>
  import("./components/admin/financial/payables/Index.vue"))
Vue.component("admin-financial-payments-index", () =>
  import("./components/admin/financial/payments/Index.vue"))
Vue.component("admin-financial-purchase-orders-index", () =>
  import("./components/admin/financial/purchase-orders/Index.vue"))
Vue.component("admin-financial-coupons-index", () =>
  import("./components/admin/financial/coupons/Index.vue"))
Vue.component("admin-financial-fpd-coupons-index", () =>
  import("./components/admin/financial/coupons/fpd/Index.vue"))
Vue.component("admin-financial-fvd-coupons-index", () =>
  import("./components/admin/financial/coupons/fvd/Index.vue"))

// Admin Membership
Vue.component("admin-society-membership-tiers-index", () =>
  import("./components/admin/society/membership/tiers/Index.vue"))
Vue.component("admin-society-membership-tier-payables-configure", () =>
  import("./components/admin/society/membership/tier-payables/Configure.vue"))
Vue.component("admin-society-user-memberships-index", () =>
  import("./components/admin/society/membership/user-memberships/Index.vue"))

// Admin System
Vue.component('admin-system-keywords-index',
  () => import('./components/admin/system/keywords/Index'));
Vue.component('admin-system-permissions-index',
  () => import('./components/admin/system/permissions/Index'));
Vue.component('admin-system-presentation-options-index',
  () => import('./components/admin/system/presentation-options/Index'));
Vue.component('admin-system-roles-index',
  () => import('./components/admin/system/roles/Index'));
Vue.component('admin-system-tags-index',
  () => import('./components/admin/system/tags/Index'));

// Others ----------------------------------------------------------------------

Vue.component(
  "confirm-action-modal",
  require("./components/ConfirmActionModal.vue").default
);
Vue.component("admin-add-keyword", () =>
  import("./components/admin/AddKeyword.vue")
);
Vue.component(
  "admin-cumulative-trace-graph",
  require("./components/admin/CumulativeTraceGraph.vue").default
);
Vue.component(
  "admin-daily-trace-graph",
  require("./components/admin/DailyTraceGraph.vue").default
);
Vue.component(
  "admin-edit-keywords",
  require("./components/EditEventKeywords.vue").default
);
Vue.component(
  "admin-event-survey-configure",
  require("./components/admin/ConfigureEventSurvey.vue").default
);
Vue.component(
  "admin-manage-role-permissions",
  require("./components/admin/ManageRolePermissions.vue").default
);
Vue.component(
  "admin-manage-user-roles",
  require("./components/admin/ManageUserRoles.vue").default
);
Vue.component(
  "admin-manage-abstract-sessions",
  require("./components/admin/Abstracts/ManageSessions.vue").default
);
Vue.component("admin-inline-session-time", () =>
  import("./components/admin/Sessions/InlineSessionTimeUpdate.vue")
);
Vue.component(
  "show-item-modal",
  require("./components/ShowItemModal.vue").default
);

Vue.component("event-payments", () =>
  import("./components/Event/EventPayments.vue")
);

Vue.component("society-event-registration-cart", () =>
  import("./components/Event/Registration/EventRegistrationCart.vue")
);
Vue.component("model-selector", () =>
  import("./components/admin/ModelSelector.vue")
);
Vue.component("society-abstract-filter", () =>
  import("./components/admin/Abstracts/AbstractFilter.vue")
);
Vue.component("batch-abstract-session-processor", () =>
  import("./components/admin/Abstracts/Batch/SessionProcessor.vue")
);
Vue.component("batch-abstract-keyword-processor", () =>
  import("./components/admin/Abstracts/Batch/KeywordProcessor.vue")
);

Vue.component("admin-event-configure-program", () =>
  import("./components/admin/Event/ConfigureProgram.vue")
);
Vue.component("admin-event-configure-session-group", () =>
  import("./components/admin/Event/ConfigureSessionGroup.vue")
);
Vue.component("admin-event-configure-session", () =>
  import("./components/admin/Event/ConfigureSession.vue")
);
Vue.component("admin-event-configure-session-abstract", () =>
  import("./components/admin/Event/ConfigureSessionAbstract.vue")
);
Vue.component("admin-event-configure-empty-timeslot", () =>
  import("./components/admin/Event/ConfigureEmptyTimeslot.vue")
);
Vue.component("admin-event-abstract-info-panel", () =>
  import("./components/admin/Event/AbstractInfoPanel.vue")
);
Vue.component("admin-event-form-recording-consent-edit", () =>
  import("./components/admin/Event/EditRecordingConsent.vue")
);
Vue.component("admin-event-form-recording-consent-sign", () =>
  import("./components/Event/SignRecordingConsent.vue")
);
Vue.component("admin-event-registrants-print-listing", () =>
  import("./components/admin/Event/RegistrantsPrintListing.vue")
);
Vue.component("admin-event-registration-onsite-manage", () =>
  import("./components/admin/Event/ManageOnsiteRegistration.vue")
);

/* Pages */
Vue.component("the-page-listing", () =>
  import("./components/pages/ThePageListing.vue")
);
Vue.component("page-editor", () => import("./components/pages/PageEditor.vue"));

Vue.component("stop-impersonation", () =>
  import("./components/admin/StopImpersonation.vue"));

// Society Registration --------------------------------------------------------
Vue.component('society-registration-form', () =>
  import("./components/society/registration/RegistrationForm.vue"));


new Vue({
  el: "#app",

  /* Re-enable tooltips whenever the app is updated. */
  updated: function () {
    // noinspection JSUnresolvedVariable
    if (!window.printLayout) {
      this.$nextTick(() => {
        Helpers.resetTooltips();
      });
    }
  }
});