<template>
  <div>
    <div class="row">
      <label for="user" class="col-md-1 control-label col-form-label">User</label>
      <div class="col-md-4">
        <span class="custom-dropdown">
          <select
            type="select"
            id="userselect"
            class="form-control"
            name="user"
            v-model="selectedUserId"
          >
            <option :value="null">Choose a user..</option>
            <option v-for="(user, index) in users" :key="index" :value="user.id">{{ user.name }}</option>
          </select>
        </span>
      </div>
      <label for="user" class="col-md-2 control-label col-form-label">Role</label>
      <div class="col-md-4" v-if="this.selectedUser && this.selectedUser.id">
        <div v-for="(role, index) in roles" :key="index">
          <div class="btn-group btn-group-toggle btn-group-sm" data-toggle="buttons">
            <label
              class="btn btn-primary"
              :class="{'active': userHasRole(role)}"
              @click.prevent="handleToggle('add', role)"
              :dusk="'role-switch-on-' + slugName(role.name)"
            >
              <input
                type="radio"
                name="options"
                :id="'role-switch-on-' + index"
                autocomplete="off"
                :checked="userHasRole(role)"
              > On
            </label>
            <label
              class="btn btn-primary"
              :class="{'active': !userHasRole(role)}"
              @click.prevent="handleToggle('remove', role)"
              :dusk="'role-switch-off-' + slugName(role.name)"
            >
              <input
                type="radio"
                name="options"
                :id="'role-switch-off-' + index"
                autocomplete="off"
                :checked="!userHasRole(role)"
              > Off
            </label>
          </div>
          <label class="pl-2" :for="'role-switch-on-' + index">{{ role.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialRoles: {
      type: Array,
      required: true,
    },
    initialUsers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      roles: this.initialRoles,
      users: this.initialUsers,
      selectedUserId: null,
    }
  },

  methods: {
    userHasRole(role) {
      return _.find(this.selectedUser.roles, aRole => {
        return aRole.id == role.id
      })
    },

    handleToggle(state, role) {
      axios.post(`/admin/roles/${role.id}/member/${state}`, {
        user_id: this.selectedUser.id,
      }).then(response => {
        $.growl.notice({ message: `Toggled role (${role.name})` })
      }).catch(error => {
        $.growl.error({ message: error.response.data.message || 'There was an error processing your request' })
      })
    },

    slugName(name) {
      return _.kebabCase(name)
    }
  },

  computed: {
    selectedUser() {
      return _.find(this.users, user => user.id == this.selectedUserId)
    },
  },
}
</script>
