<template>
  <div>
    <div class="row">
      <label for="user" class="col-md-1 control-label col-form-label">Role</label>
      <div class="col-md-4">
        <span class="custom-dropdown">
          <select
            type="select"
            id="roleselect"
            class="form-control"
            name="role"
            v-model="selectedRoleId"
          >
            <option :value="null">Choose a role..</option>
            <option v-for="(role, index) in roles" :key="index" :value="role.id">{{ role.name }}</option>
          </select>
        </span>
      </div>
      <label for="user" class="col-md-2 control-label col-form-label">Permission</label>
      <div class="col-md-4" v-if="this.selectedRole && this.selectedRole.id">
        <div v-for="(permission, index) in permissions" :key="index" class="toggle-buttons">
          <div class="btn-group btn-group-toggle btn-group-sm" data-toggle="buttons">
            <label
              class="btn btn-primary"
              :class="{'active': roleHasPermission(permission)}"
              @click.prevent="handleToggle('add', permission)"
              :dusk="'permission-switch-on-' + slugName(permission.name)"
            >
              <input
                type="radio"
                name="options"
                :id="'permission-switch-on-' + index"
                autocomplete="off"
                :checked="roleHasPermission(permission)"
                value="on"
              > On
            </label>
            <label
              class="btn btn-primary"
              :class="{'active': !roleHasPermission(permission)}"
              @click.prevent="handleToggle('remove', permission)"
              :dusk="'permission-switch-off-' + slugName(permission.name)"
            >
              <input
                type="radio"
                name="options"
                :id="'permission-switch-off-' + index"
                autocomplete="off"
                :checked="!roleHasPermission(permission)"
                value="off"
              > Off
            </label>
          </div>
          <label class="pl-2" :for="'permission-switch-on-' + index">{{ permission.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialRoles: {
      type: Array,
      required: true,
    },
    initialPermissions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      roles: this.initialRoles,
      permissions: this.initialPermissions,
      selectedRoleId: null
    }
  },

  methods: {
    roleHasPermission(permission) {
      return _.find(this.selectedRole.permissions, aPermission => {
        return aPermission.id == permission.id
      })
    },

    handleToggle(state, permission) {
      axios.post(`/admin/roles/${this.selectedRole.id}/permission/${state}`, {
        permission_id: permission.id
      }).then(response => {
        $.growl.notice({ message: `Toggled permission (${permission.name})` })
      }).catch(error => {
        $.growl.error({ message: error.response.data.message || 'There was an error processing your request' })
      })
    },

    slugName(name) {
      return _.kebabCase(name)
    }
  },

  computed: {
    selectedRole() {
      return _.find(this.roles, role => role.id == this.selectedRoleId)
    },
  },
}
</script>
