import {Helpers} from "../common/functions";

export default {
  methods: {
    /**
     * Use this to replace FormData when the server does not recognize
     * it and only accepts a regular JSON object.
     *
     * @param form JQuery form wrapper
     */
    getFormData(form) {
      return form.serializeArray().reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
      }, {});
    },

    notifyAjaxError(error) {
      if (error.response) {
        if (error.response.data.errors) {
          const errs = error.response.data.errors;
          const errKey = Object.getOwnPropertyNames(errs)[0];
          Helpers.notify('error', errs[errKey][0]);
          return;
        }
        let messageComponents = [];
        if (error.response.message)
          messageComponents.push(error.response.message);
        if (error.response.data && error.response.data.message)
          messageComponents.push(error.response.data.message);
        if (error.response.statusText)
          messageComponents.push(error.response.statusText);
        if (error.response.status)
          messageComponents.push("(" + error.response.status + ")");
        Helpers.notify('error', messageComponents.join(" "));
      } else if (error.request) {
        Helpers.notify('error', error.request);
      } else {
        Helpers.notify('error', error.message);
      }
    }
  }
};
