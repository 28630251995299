<template>
    <div>
        <div class="form-group">
            <div class="form-row">
                <div class="col-8">
                    <input class="form-control col"
                            type="text"
                            name="keyword"
                            v-model="editedKeyword"
                            id="keyword"
                            v-on:keyup.enter="editKeyword(editedKeyword)">
                </div>
                <div class="col-4">
                    <button
                          type="button"
                          @click.prevent="editKeyword(editedKeyword)"
                          class="btn btn-primary btn-sm"
                          dusk="add-keyword">Edit Keyword</button>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <strong>Associated Events</strong>
                <br/>
                <template v-if="keywordEvents.length == 0">
                    <p class="small">
                        There are no events currently associated with this keyword.
                        Click on the events below to add them to this keyword.
                    </p>
                </template>
                <template v-else>
                    <span v-for="keywordEvent in keywordEvents"
                            class="badge badge-pill badge-info mr-1 my-1 p-2">
                        {{ keywordEvent.name }}
                        <a @click.prevent="removeEvent(keywordEvent.id)">
                            <i class="fas fa-times-circle"></i>
                        </a>
                    </span>
                </template>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <strong>Available Events</strong>
                <p class="small">
                    Click on one or more events below to add them to this keyword.
                </p>
                <template v-if="events.length == 0">
                    <p>There are no events currently setup.</p>
                </template>
                <template v-else>
                    <div class="row">
                        <div v-for="event in events"
                                v-if="isKeywordEvent(event.id) < 0"
                                class="col-12">
                            <a @click.prevent="addEvent(event.id)"
                                    class="badge badge-info keyword my-1 py-1 small">
                                <span class="">
                                    ID: {{ event.id }} -
                                    {{ event.name }}
                                </span>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .badge {
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.16), 0 1px 5px 0 rgba(0,0,0,.12);
    }
    .keyword {
        line-height: 1.1rem;
        text-align: left;
        white-space: unset;
    }
</style>

<script>
export default {
    props: {
        events: {
            required: true,
            type: Array,
        },
    },

    mounted() {
        let _this = this;

        // Listen to event trigger called "editAdminKeyword"
        $(document).on("editAdminKeyword", function(event, keywordId) {
            axios.get(route('keywords-get', {
                    keyword: keywordId,
                })).then(response => {
                    _this.keyword = response.data.keyword;
                    _this.editedKeyword = response.data.keyword.keyword;
                    _this.keywordEvents = response.data.keywordEvents;
                }).catch(error => {
                    $.growl.error({
                        message: error.response.data.message
                            || 'There was an error processing your request' })
                })
        });
    },

    data() {
        return {
            keyword: '',
            editedKeyword: '',
            keywordEvents: [],
        }
    },

    methods: {
        isKeywordEvent(eventId) {
            for(let k in this.keywordEvents) {
                if (this.keywordEvents[k].id == eventId) {
                    return k;
                }
            }

            return -1;
        },

        editKeyword(editedKeyword) {
            axios.get(route('keywords-edit', {
                    keyword: this.keyword.id,
                    name: editedKeyword.trim(),
                })).then(response => {
                    $.growl.notice({ message: response.data.message })
                }).catch(error => {
                    $.growl.error({
                        message: error.response.data.message
                            || 'There was an error processing your request' })
                })
        },

        addEvent(eventId) {
            axios.get(route('keywords-event-add', {
                    keyword: this.keyword.id,
                    event: eventId,
                })).then(response => {
                    $.growl.notice({ message: response.data.message })
                    this.keywordEvents = response.data.newEvents
                }).catch(error => {
                    $.growl.error({
                        message: error.response.data.message
                            || 'There was an error processing your request' })
                })
        },

        removeEvent(eventId) {
            axios.delete(route('keywords-event-delete', {
                    keyword: this.keyword.id,
                    event: eventId,
                })).then(response => {
                    if(response.data && !response.data.success) {
                        $.growl.error({ message: response.data.message })
                    }
                    else {
                        $.growl.notice({ message: 'Event successfully removed from keyword!' })
                        this.keywordEvents.splice(this.keywordEvents.findIndex(ke => ke.id === eventId), 1)
                    }
                }).catch(error => {
                    $.growl.error({
                        message: error.response.data.message
                            || 'There was an error processing your request' })
                })
        },
    }
}
</script>
