<template>
  <div>
    <slot name="button" :modal-id="modalId"></slot>
    <div
      class="modal fade"
      :id="modalId"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirm-action-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirm-action-modal-label">Please confirm this action</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="message"></slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary mr-4" data-dismiss="modal">Close</button>
            <button
              type="button"
              @click.prevent="handleConfirm"
              data-dismiss="modal"
              class="btn btn-primary"
              :dusk="'confirm-delete-' + modalId"
            >Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Are you sure you would like to delete this item?',
    },
    buttonClasses: {
      type: String,
      default: 'btn btn-primary',
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm')
    },
  },

  computed: {
    modalId() {
      return _.uniqueId('confirm-action-')
    },
  },
}
</script>
