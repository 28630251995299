<script>
import { Line } from 'vue-chartjs'

export default {
    extends: Line,

    data() {
        return {
            traceDates: [],
            traceGraphCounts: [],
        };
    },

    mounted() {
        axios.get(
            route('admin-user-profiles-summary-cumulative')
        ).then(response => {
            this.traceDates = response.data.tracedates;
            this.traceGraphCounts = response.data.tracegraphcounts;
            this.makeChart();
        }).catch(error => {
            $.growl.error({
                message: error.response.data.message
                    || 'There was an error processing your request'
            })
        });
    },

    methods: {
        makeChart() {
            var datasets = [{
                data: this.traceGraphCounts,
                label: "Total Confirmed Members",
                borderColor: '#001B9F',
                backgroundColor: '#001B9F',
                fill: false
            }];

            this.renderChart({
                labels: this.traceDates,
                datasets: datasets,
            },
            {
                elements: {
                    point: { radius: 0 },
                    line: { tension: 0, borderWidth: 1.5 },
                },
                scales: {
                    xAxes: [{ scaleLabel: { display: true, labelString: 'Past 60 Days' } }],
                    yAxes: [{ 
                        scaleLabel: { display: true, labelString: 'Number of Memberships' }, 
                        ticks: { beginAtZero: true }
                    }],
                }
            })
        }
    },
}
</script>
