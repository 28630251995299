/**
 * Syntactic convenience to avoid  having to suppress IDE warnings in multiple
 * places and potentially prepare for using the Vue router in the future.
 */
import {DateTime} from "luxon";
import {createToastInterface} from "vue-toastification";

export function getNamedRoute(name, params) {
  // noinspection JSUnresolvedFunction
  if (params)
    // noinspection JSUnresolvedFunction
    return route(name, params);
  else
    // noinspection JSUnresolvedFunction
    return route(name);
}

export function getFormattedDate(isoDateStr) {
  return DateTime.fromISO(isoDateStr).toFormat('yyyy-MM-dd');
}

export function getFormattedDateTime(isoDateStr, format) {
  if (format)
    return DateTime.fromISO(isoDateStr).toFormat(format);
  else
    return getFormattedDate(isoDateStr);
}

export function trimEmptyStringProps(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null
      || (typeof obj[key] === 'string'
        && obj[key].trim().length === 0))
      delete obj[key];
  });
}

/**
 * Collects helper functions under one namespace for export. Also avoids the
 * need to suppress the same warning multiple times.
 */
export class Helpers {
  static  toast = createToastInterface();

  static getFormattedDate(isoDateStr) {
    return getFormattedDate(isoDateStr);
  }

  static getFormattedDateTime(isoDateStr, format) {
    return getFormattedDateTime(isoDateStr, format);
  }

  static getNamedRoute(name, params) {
    return getNamedRoute(name, params);
  }

  static notify(type, message) {
    switch (type) {
      case 'success':
        Helpers.toast.success(message);
        break;
      case 'error':
        Helpers.toast.error(message);
        break;
      case 'info':
      default:
        Helpers.toast.info(message);
        break;
    }
  }

  static resetTooltips() {
    const selector = '[data-toggle="tooltip"]';

    /* On occasion, the jQuery library may not have been loaded, leading to
    * benign errors. */
    if (typeof $ !== 'undefined') {
      $(selector).tooltip('dispose');
      $(selector).tooltip();
    }
  }

  static trimEmptyStringProps(obj) {
    trimEmptyStringProps(obj);
  };
}